// jquery
//var $ = require('jquery');


import Swiper from 'swiper/bundle'; // 全ての機能が入っている

$(document).ready(function() {
  $('.drawer').drawer();
});


var elementVisible = {};

var observer = new IntersectionObserver(function(entries) {
  entries.forEach(function(entry) {
    var element = entry.target;
    if (entry.isIntersecting && !elementVisible[element]) {
      elementVisible[element] = true;
      window.addEventListener('scroll', handleScroll);
    }
  });
}, { threshold: 0 });

var elements = document.querySelectorAll('.fixed-element');
var innerElements = document.querySelectorAll('.fixed-element img');

elements.forEach(function(element) {
  observer.observe(element);
});

function handleScroll() {
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
  elements.forEach(function(element, index) {
    var distanceFromDifference = element.getBoundingClientRect().height - element.getBoundingClientRect().width;
    //elementの高さを取得
    var distanceFromTop = element.getBoundingClientRect().top;
    var distanceFromBottom = element.getBoundingClientRect().bottom;
    var distanceFromHeight = element.getBoundingClientRect().height;
    var distanceFromStart = window.innerHeight - element.getBoundingClientRect().top;
    console.log(distanceFromTop + ", " + distanceFromBottom + ", " + distanceFromHeight+ ", " + scrollTop + ", " + distanceFromStart);

    if (distanceFromStart > 0) {
      if(distanceFromTop < 0) {
        var addStep = distanceFromTop / -50;
      } else {
        var addStep = 0;
      }
      var elementTop = distanceFromTop / 5;
      var elementScale = (distanceFromStart / 100) + addStep;
      console.log('Scale:' + elementScale);
    }
    // var distanceFromTop = window.innerHeight - element.getBoundingClientRect().top;
    // var elementTop = distanceFromTop > -5 ? distanceFromTop * 0.45 - 360 : -360;
    // var elementScale = distanceFromTop > -5 ? distanceFromTop * 0.02 + distanceFromDifference*0.01 : 0;

    innerElements[index].style.transform = `translate3d(0, ${elementTop}px, ${elementScale}px)`;
  });
}
